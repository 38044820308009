import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agile-web-development',
  templateUrl: './agile-web-development.component.html',
  styleUrls: ['./agile-web-development.component.css']
})
export class AgileWebDevelopmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
