import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-application',
  templateUrl: './mobile-application.component.html',
  styleUrls: ['./mobile-application.component.css']
})
export class MobileApplicationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
