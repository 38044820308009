import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conversion-rate-optimization',
  templateUrl: './conversion-rate-optimization.component.html',
  styleUrls: ['./conversion-rate-optimization.component.css']
})
export class ConversionRateOptimizationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
