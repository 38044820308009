import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
@Component({
  selector: 'app-ng',
  templateUrl: './ng.component.html',
  styleUrls: ['./ng.component.css']
})
export class NgComponent implements OnInit {
  constructor() { }
  ngOnInit() {
    
   
  }

}
