import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pay-for-performance-seo',
  templateUrl: './pay-for-performance-seo.component.html',
  styleUrls: ['./pay-for-performance-seo.component.css']
})
export class PayForPerformanceSeoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
