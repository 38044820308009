import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fractal-analytics',
  templateUrl: './fractal-analytics.component.html',
  styleUrls: ['./fractal-analytics.component.css']
})
export class FractalAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
