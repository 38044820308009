import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-management-systems',
  templateUrl: './content-management-systems.component.html',
  styleUrls: ['./content-management-systems.component.css']
})
export class ContentManagementSystemsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
