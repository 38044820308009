import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-food-delivery-app',
  templateUrl: './food-delivery-app.component.html',
  styleUrls: ['./food-delivery-app.component.css']
})
export class FoodDeliveryAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
