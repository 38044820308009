import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SeoComponent } from './seo/seo.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { SocialMediaMarketingComponent } from './social-media-marketing/social-media-marketing.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './product/product.component';
import { WorkComponent } from './work/work.component';
import { ClientsComponent } from './clients/clients.component';
import { SkymetweatherComponent } from './skymetweather/skymetweather.component';
import { BigRockComponent } from './big-rock/big-rock.component';
import { FractalAnalyticsComponent } from './fractal-analytics/fractal-analytics.component';
import { CloudnineCareComponent } from './cloudnine-care/cloudnine-care.component';
import { ClientReviewComponent } from './client-review/client-review.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { WebDesignOverviewComponent } from './web-design-overview/web-design-overview.component';
import { ResponsiveDesignOverviewComponent } from './responsive-design-overview/responsive-design-overview.component';
import { MarketingCollateralsComponent } from './marketing-collaterals/marketing-collaterals.component';
import { EcommerceDevelopmentComponent } from './ecommerce-development/ecommerce-development.component';
import { MobileApplicationComponent } from './mobile-application/mobile-application.component';
import { EcommerceSeoComponent } from './ecommerce-seo/ecommerce-seo.component';
import { SearchMediaOptimizationComponent } from './search-media-optimization/search-media-optimization.component';
import { UberTaxiComponent } from './uber-taxi/uber-taxi.component';
import { UbereatsScriptComponent } from './ubereats-script/ubereats-script.component';
import { TinderMobileDatingAppComponent } from './tinder-mobile-dating-app/tinder-mobile-dating-app.component';
import { WhatsappScriptComponent } from './whatsapp-script/whatsapp-script.component';
import { MobileAppDevelopmentComponent } from './hire-us/mobile-app-development/mobile-app-development.component';
import { WebAppDevelopmentComponent } from './hire-us/web-app-development/web-app-development.component';
import { TexiAppComponent } from './app/texi-app/texi-app.component';
import { FoodDeliveryAppComponent } from './app/food-delivery-app/food-delivery-app.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { AgileWebDevelopmentComponent } from './agile-web-development/agile-web-development.component';
import { ContentManagementSystemsComponent } from './content-management-systems/content-management-systems.component';
import { PayForPerformanceSeoComponent } from './pay-for-performance-seo/pay-for-performance-seo.component';
import { LocalSeoComponent } from './local-seo/local-seo.component';
import { MobileSeoComponent } from './mobile-seo/mobile-seo.component';
import { ConversionRateOptimizationComponent } from './conversion-rate-optimization/conversion-rate-optimization.component';
import { CroProcessComponent } from './cro-process/cro-process.component';
import { GoogleAdwordsManagementComponent } from './google-adwords-management/google-adwords-management.component';
import { BingAdsManagementComponent } from './bing-ads-management/bing-ads-management.component';
import { SmoProcessComponent } from './smo-process/smo-process.component';
import { CasestudyComponent } from './casestudy/casestudy.component';
import { CareerComponent } from './career/career.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';





const routes: Routes = [
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'app/texi-app', component: TexiAppComponent },
  { path: 'app/food-delivery-app', component: FoodDeliveryAppComponent },
  { path: 'hire-us/mobile-app-development', component: MobileAppDevelopmentComponent },
  { path: 'hire-us/web-app-development', component: WebAppDevelopmentComponent },
  { path: 'whatsapp-script', component: WhatsappScriptComponent },
  { path: 'tinder-mobile-dating-app', component: TinderMobileDatingAppComponent },
  { path: 'ubereats-script', component: UbereatsScriptComponent },
  { path: 'uber-taxi', component: UberTaxiComponent },
  { path: 'ecommerce-seo', component: EcommerceSeoComponent },
  { path: 'search-media-optimization', component: SearchMediaOptimizationComponent },
  { path: '', redirectTo: 'home-page', pathMatch: 'full' },
  { path: 'mobile-application', component: MobileApplicationComponent },
  { path: 'ecommerce-development', component: EcommerceDevelopmentComponent },
  { path: 'web-design-overview', component: WebDesignOverviewComponent },
  { path: 'responsive-design-overview', component: ResponsiveDesignOverviewComponent },
  { path: 'marketing-collaterals', component: MarketingCollateralsComponent },
  { path: 'work', component: WorkComponent },
  { path: 'our-team', component: OurTeamComponent },
  { path: 'social-media-marketing', component: SocialMediaMarketingComponent },
  { path: 'seo', component: SeoComponent },
  { path: 'web-development', component: WebDevelopmentComponent },
  { path: 'web-design', component: WebDesignComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'product', component: ProductComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'client-review', component: ClientReviewComponent },
  { path: 'skymetweather', component: SkymetweatherComponent },
  { path: 'big-rock', component: BigRockComponent },
  { path: 'fractal-analytics', component: FractalAnalyticsComponent },
  { path: 'cloudnine-care', component: CloudnineCareComponent },
  { path: 'agile-web-development', component: AgileWebDevelopmentComponent },
  { path: 'content-management-systems', component: ContentManagementSystemsComponent },
  { path: 'pay-for-performance-seo', component: PayForPerformanceSeoComponent },
  { path: 'local-seo', component: LocalSeoComponent },
  { path: 'mobile-seo', component: MobileSeoComponent },
  { path: 'conversion-rate-optimization', component: ConversionRateOptimizationComponent },
  { path: 'cro-process', component: CroProcessComponent },
  { path: 'google-adwords-management', component: GoogleAdwordsManagementComponent },
  { path: 'bing-ads-management', component: BingAdsManagementComponent },
  { path: 'smo-process', component: SmoProcessComponent },
  { path: 'casestudy', component: CasestudyComponent },
  { path: 'career', component: CareerComponent },
  { path: 'home-slider', component: HomeSliderComponent },










];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
