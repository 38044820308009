import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tinder-mobile-dating-app',
  templateUrl: './tinder-mobile-dating-app.component.html',
  styleUrls: ['./tinder-mobile-dating-app.component.css']
})
export class TinderMobileDatingAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
