import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsive-design-overview',
  templateUrl: './responsive-design-overview.component.html',
  styleUrls: ['./responsive-design-overview.component.css']
})
export class ResponsiveDesignOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
