import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { FooterPageComponent } from './footer-page/footer-page.component';
import { NgComponent } from './ng/ng.component';
import { SeoComponent } from './seo/seo.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { SocialMediaMarketingComponent } from './social-media-marketing/social-media-marketing.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ProductComponent } from './product/product.component';
import { WorkComponent } from './work/work.component';
import { TeamComponent } from './team/team.component';
import { ClientsComponent } from './clients/clients.component';
import { SkymetweatherComponent } from './skymetweather/skymetweather.component';
import { BigRockComponent } from './big-rock/big-rock.component';
import { FractalAnalyticsComponent } from './fractal-analytics/fractal-analytics.component';
import { CloudnineCareComponent } from './cloudnine-care/cloudnine-care.component';
import { ClientReviewComponent } from './client-review/client-review.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { WebDesignOverviewComponent } from './web-design-overview/web-design-overview.component';
import { ResponsiveDesignOverviewComponent } from './responsive-design-overview/responsive-design-overview.component';
import { MarketingCollateralsComponent } from './marketing-collaterals/marketing-collaterals.component';
import { EcommerceDevelopmentComponent } from './ecommerce-development/ecommerce-development.component';
import { MobileApplicationComponent } from './mobile-application/mobile-application.component';
import { EcommerceSeoComponent } from './ecommerce-seo/ecommerce-seo.component';
import { SearchMediaOptimizationComponent } from './search-media-optimization/search-media-optimization.component';
import { UberTaxiComponent } from './uber-taxi/uber-taxi.component';
import { UbereatsScriptComponent } from './ubereats-script/ubereats-script.component';
import { TinderMobileDatingAppComponent } from './tinder-mobile-dating-app/tinder-mobile-dating-app.component';
import { WhatsappScriptComponent } from './whatsapp-script/whatsapp-script.component';
import { MobileAppDevelopmentComponent } from './hire-us/mobile-app-development/mobile-app-development.component';
import { WebAppDevelopmentComponent } from './hire-us/web-app-development/web-app-development.component';
import { FoodDeliveryAppComponent } from './app/food-delivery-app/food-delivery-app.component';
import { TexiAppComponent } from './app/texi-app/texi-app.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { AgileWebDevelopmentComponent } from './agile-web-development/agile-web-development.component';
import { ContentManagementSystemsComponent } from './content-management-systems/content-management-systems.component';
import { PayForPerformanceSeoComponent } from './pay-for-performance-seo/pay-for-performance-seo.component';
import { LocalSeoComponent } from './local-seo/local-seo.component';
import { MobileSeoComponent } from './mobile-seo/mobile-seo.component';
import { ConversionRateOptimizationComponent } from './conversion-rate-optimization/conversion-rate-optimization.component';
import { CroProcessComponent } from './cro-process/cro-process.component';
import { GoogleAdwordsManagementComponent } from './google-adwords-management/google-adwords-management.component';
import { BingAdsManagementComponent } from './bing-ads-management/bing-ads-management.component';
import { SmoProcessComponent } from './smo-process/smo-process.component';
import { CasestudyComponent } from './casestudy/casestudy.component';
import { CareerComponent } from './career/career.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    FooterPageComponent,
    NgComponent,
    SeoComponent,
    WebDevelopmentComponent,
    WebDesignComponent,
    SocialMediaMarketingComponent,
    ContactComponent,
    AboutUsComponent,
    ProductComponent,
    WorkComponent,
    TeamComponent,
    ClientsComponent,
    SkymetweatherComponent,
    BigRockComponent,
    FractalAnalyticsComponent,
    CloudnineCareComponent,
    ClientReviewComponent,
    OurTeamComponent,
    WebDesignOverviewComponent,
    ResponsiveDesignOverviewComponent,
    MarketingCollateralsComponent,
    EcommerceDevelopmentComponent,
    MobileApplicationComponent,
    EcommerceSeoComponent,
    SearchMediaOptimizationComponent,
    UberTaxiComponent,
    UbereatsScriptComponent,
    TinderMobileDatingAppComponent,
    WhatsappScriptComponent,
    MobileAppDevelopmentComponent,
    WebAppDevelopmentComponent,
    FoodDeliveryAppComponent,
    TexiAppComponent,
    ThankYouComponent,
    AgileWebDevelopmentComponent,
    ContentManagementSystemsComponent,
    PayForPerformanceSeoComponent,
    LocalSeoComponent,
    MobileSeoComponent,
    ConversionRateOptimizationComponent,
    CroProcessComponent,
    GoogleAdwordsManagementComponent,
    BingAdsManagementComponent,
    SmoProcessComponent,
    CasestudyComponent,
    CareerComponent,
    HomeSliderComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RecaptchaModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
