import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smo-process',
  templateUrl: './smo-process.component.html',
  styleUrls: ['./smo-process.component.css']
})
export class SmoProcessComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
