import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-seo',
  templateUrl: './mobile-seo.component.html',
  styleUrls: ['./mobile-seo.component.css']
})
export class MobileSeoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
